<template>
  <div class="mobile-register">
    <div class="mobile-register-view">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="username">
          <p class="mobile-register-view-title">{{ $t('userName') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input v-model="form.username" :placeholder="$t('placeholder')+$t('userName')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="mobile">
          <p class="mobile-register-view-title  title-top">{{ $t('mobile') }}</p>
          <div class="mobile-register-view-content mobile-register-view-mobile">
            <div class="codice-paese">
              <el-dropdown @command="changePhoneCode">
          <span class="el-dropdown-link">
            {{ phoneCodeValue }}
          </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-for="item in phoneCodeList"
                      :key="item.id"
                      :command="item"
                  >
                    {{ item.country }}+{{ item.telephone_code }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <p class="line"></p>
            <el-input v-model="form.mobile" :placeholder="$t('placeholder')+$t('mobile')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <p class="mobile-register-view-title  title-top">{{ $t('vCodeText') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input v-model="form.verificationCode" :placeholder="$t('placeholder')+$t('vCodeText')"></el-input>
            <el-button type="primary" @click="changeCode" :disabled="vCodeLoading">{{ vCodeBtnText }}</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <p class="mobile-register-view-title title-top">{{ $t('password') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input type="password" v-model="form.password"
                      :placeholder="$t('placeholder')+$t('password')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="verifyPassword">
          <p class="mobile-register-view-title title-top">{{ $t('confirm') + $t('password') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input type="password" v-model="form.verifyPassword"
                      :placeholder="$t('placeholder') + $t('confirm') + $t('password')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="email">
          <p class="mobile-register-view-title title-top">{{ $t('email') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input v-model="form.email" :placeholder="$t('placeholder')+$t('email')"></el-input>
          </div>
        </el-form-item>
<!--        <el-form-item prop="invite_code">-->
<!--          <p class="mobile-register-view-title title-top">{{ $t('invitationCode') }}</p>-->
<!--          <div class="mobile-register-view-content mobile-register-view-vcode">-->
<!--            <el-input v-model="form.invite_code" :placeholder="$t('placeholder')+$t('invitationCode')"></el-input>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-button class="submit-login" type="primary" @click="submitForm">{{ $t('register') }}</el-button>
        <div class="agreement">
          <el-checkbox class="and" v-model="treatyChecked">
          </el-checkbox>
          <span class="and">&nbsp;&nbsp;{{ $t('agree') }}</span>
          <span @click="showAgreement(1)" style="color: #3875FB; cursor: pointer;">{{ $t('userAgreement') }}</span>
          <span class="and">{{$t("registerEmail.he")}}</span>
          <span @click="showAgreement(6)" style="color: #3875FB; cursor: pointer;">{{ $t('privacyPolicy') }}</span>
        </div>
        <p class="login pointer" @click="goLogin">{{ $t('alreadyHaveAnAccountToRegister') }}</p>
      </el-form>
    </div>
	<!-- 协议弹窗 -->
	<AgreementPopup ref="popupAgreement"></AgreementPopup>
  </div>
</template>

<script>
import {getTelephoneCode} from "@/api/Common";
import {mobileLogin, registerUser} from "@/api/User";
import {sendVCode} from "@/utils/common";
import AgreementPopup from "@/components/popup/AgreementPopup.vue"

export default {
  props: {},
  data() {
    return {
		agreenDetail: {
			title: '关于我们',
			content: `
			<p style="color: green;">haaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</p>
			<p>bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb</p>
			<p>bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb</p>
			<p>bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			<p>cccccccccccccccccccccccccccccccccb</p>
			`
		},
      mobile: '',
      vcode: '',
      treatyChecked: false,
      form: {
        username: "",
        mobile: '',
        password: '',
        verifyPassword: '',
        verificationCode: '',
        invite_code: '',
        email: ''
      },
      rules: {
        username: [
          {required: true, message: this.$t('placeholder') + this.$t('userName'), trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: this.$t('placeholder') + this.$t('mobile'), trigger: 'blur'}
        ],
        password: [
          {required: true, message: this.$t('placeholder') + this.$t('password'), trigger: 'blur'}
        ],
        verifyPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('placeholder') + this.$t('confirm') + this.$t('password')))
              } else if (value !== this.form.password) {
                callback(new Error(this.$t('thePasswordEnteredTwiceDoesNotMatch')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        verificationCode: [
          {required: true, message: this.$t('placeholder') + this.$t('vCodeText'), trigger: 'blur'}
        ],
        email: [
          {
            required: false,
            validator: async (rule, value, callback) => {
              if (value == '') {
                callback(new Error(this.$t('placeholder') + this.$t('email')))
              } else if (value) {
                let reg = new RegExp(/^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/)
                if (!reg.test(value)) {
                  callback(new Error(this.$t('theMailboxFormatIsIncorrect')))
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
      },
      loading: false,
      phoneCodeList: '',
      phoneCodeValue: '中国+86',
      telephoneCode: '',
      vCodeLoading: false,
      vCodeCount: 60,
      vCodeBtnText: this.$t('testGetCode'),
	  agreeType: 1,
    }
  },
  components: {
	  AgreementPopup
  },
  computed: {},
  created() {
	  
  },
  mounted() {
    this.initTelephoneCode()
  },
  watch: {},
  methods: {
    sendVCode,
    showAgreement(type) {
      console.log('协议')
	  this.agreeType = type
	  this.$refs.popupAgreement.open()
	  this.$refs.popupAgreement.getAgreement(this.agreeType)
    },
    // 获取国地区手机编码
    initTelephoneCode() {
      getTelephoneCode().then((res) => {
        console.log('获取国地区手机编码成功:', res)
        this.phoneCodeList = res.data.list
      }).catch(err => {
        console.log('获取国地区手机编码失败:', err)
      })
    },
    changePhoneCode(command) {
      this.phoneCodeValue = `${command.country}+${command.telephone_code}`
      this.telephoneCode = command.telephone_code
    },
    changeCode() {
      if (this.form.mobile.trim()) {
        this.sendVCode(this.telephoneCode, this.form.mobile)
        this.countDown()
      } else {
        this.form.mobile = ''
        this.$message.error(this.$t('placeholder') + this.$t('mobile'))
      }
    },
    countDown() {
      let inter = setInterval(() => {
        if (this.vCodeCount == 0) {
          clearInterval(inter)
          this.vCodeLoading = false
          this.vCodeCount = 60
          this.vCodeBtnText = this.$t('testGetCode')
        } else {
          this.vCodeLoading = true
          this.vCodeCount--
          this.vCodeBtnText = `${this.vCodeCount}${this.$t('tryAgainInSeconds')}`
        }
      }, 1000)
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.treatyChecked) {
            registerUser(this.form).then(res => {
              console.log('注册成功:', res)
              this.goLogin()
            }).catch(err => {
              console.log('注册失败:', err)
            })
          } else {
            this.$message.warning(this.$t('agreeToTheAgreement'))
          }
          this.loading = true
          console.log(this.treatyChecked)
        } else {
          return false;
        }
      });
    },
    goLogin() {
      this.$router.push({
        path: '/login/vcodelogin'
      })
    }
  }
}
</script>

<style scoped lang='less'>
.mobile-register {
  margin-top: 23px;
  padding: 60px;
  background-color: @recommendMusicianListBGColor;
  border-radius: 6px;

  &-view {
    width: 300px;
    margin: 0 auto;

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    &-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
    }

    .title-top {
      margin-top: 40px;
    }

    &-content {
      width: 300px;
      height: 42px;
      margin-top: 10px;
      background-color: @loginInputBgColor;
      border-radius: 4px;

      .codice-paese {
        line-height: 42px;
        padding-left: 12px;
        width: 22%;

        ::v-deep .el-dropdown {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: @buttonTextColor;
        }
      }

      .line {
        width: 1px;
        height: 22px;
        background: @buttonTextColor;
        margin: 10px;
      }

      ::v-deep .el-input__inner {
        border: none;
        line-height: 42;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @buttonTextColor;
        padding: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &-mobile {
      display: flex;
    }

    &-vcode {
      display: flex;
      justify-content: space-between;

      ::v-deep .el-input__inner {
        padding-left: 12px;
      }
    }

    .submit-login {
      width: 100%;
      margin-top: 40px;
    }

    .agreement {
      margin-top: 12px;

      .and {
        color: @buttonTextColor;
      }
    }

    .login {
      margin-top: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
      text-align: center;
    }

    ::v-deep .el-button--primary {
      background-color: #3370FF !important;
      border-color: #3370FF !important;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
